import {
  BOWLS,
  DELIVERY,
  DELIVERY_LABEL,
  DRINKS,
  FULL_SIZE,
  HALF_SIZE,
  LARGE_SIZE,
  NO_TIME_SLOTS_PRESENT,
  PICK_UP_LABEL,
  PICK_UP_ORDER_TYPE,
  REGULAR_SIZE,
  SALADS,
  SANDWICH_TRAY,
  SMALL_SIZE,
  SOUP_TRAY,
} from '../constants';

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0)?.toUpperCase() + value.slice(1);
};
export const isSizeEqual = (item, reward) => {
  return (
    item.item_size?.toLowerCase() == reward.redirect_item_size?.toLowerCase()
  );
};
export const isCartEmpty = (Cart) =>
  Cart.cart.itemTree && Object.keys(Cart.cart.itemTree).length == 0;
export const getExpiry_year = (values) => {
  if (Number(values.card.expiration.split('/')[1]) < 100) {
    return Number(
      new Date().getFullYear().toString().substring(0, 2) +
        values.card.expiration.split('/')[1],
    );
  } else {
    return values.card.expiration.split('/')[1];
  }
};
export const formatDate = (date) => {
  const currentDate = new Date();
  const dateSplit = date ? date.split('-') : ['2023', '01', '01'];
  const selectedDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
  let updateDateFormat = '';
  if (
    currentDate.getDate() == selectedDate.getDate() &&
    currentDate.getDay() == selectedDate.getDay()
  ) {
    updateDateFormat =
      'Today, ' +
      selectedDate.toLocaleDateString('en-US', {
        weekday: 'long',
        // year: "numeric",
        month: 'short',
        day: 'numeric',
      });
  } else {
    updateDateFormat = selectedDate.toLocaleDateString('en-US', {
      weekday: 'long',
      // year: "numeric",
      month: 'short',
      day: 'numeric',
    });
  }
  return updateDateFormat;
};
export const formatTime = (time: string, orderDetails) => {
  if (orderDetails?.time == '' || orderDetails?.time == NO_TIME_SLOTS_PRESENT) {
    return NO_TIME_SLOTS_PRESENT;
  }
  if (!orderDetails?.time?.includes('ASAP')) {
    const hoursMinsSec = orderDetails?.time?.split(':');
    time = formatAMPM(hoursMinsSec[0], hoursMinsSec[1]);
  } else {
    time = orderDetails?.time;
  }
  return time;
};
export const formatAMPM = (hours, mins) => {
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12;
  mins = mins < 10 ? `${mins}` : mins;
  hours = hours < 10 ? `0${hours}` : hours;
  const formatedTime = `${hours}:${mins} ${ampm}`;

  return formatedTime;
};

export const scrollOnSize = (divRef, offset) => {
  setTimeout(() => {
    const yOffset = offset;
    const ScrollYCordinates =
      divRef?.current?.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: ScrollYCordinates, behavior: 'smooth' });
  }, 250);
};
export const getSizeName = (categoryName: string) => {
  if (
    categoryName?.toLowerCase() === SANDWICH_TRAY ||
    categoryName?.toLowerCase() === SOUP_TRAY ||
    categoryName?.toLowerCase() === DRINKS
  )
    return LARGE_SIZE;
  else return FULL_SIZE;
};

export const getSizeNameInCaseOfHalf = (categoryName: string, size: string) => {
  if (categoryName?.toLowerCase() === DRINKS) return REGULAR_SIZE.upper_case;
  if (
    categoryName?.toLowerCase() === SANDWICH_TRAY ||
    categoryName?.toLowerCase() === SOUP_TRAY
  )
    return SMALL_SIZE.upper_case;
  else return size;
};

export const itemSizeForMobile = (
  sizeTextFor: 'HALF' | 'FULL',
  categoryName: string,
) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return categoryName?.toLowerCase() === DRINKS
        ? REGULAR_SIZE.title_case
        : HALF_SIZE.title_case;
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName).title_case;
    default:
      break;
  }
};

export const itemSizeForDesktop = (
  sizeTextFor: 'HALF' | 'FULL',
  categoryName: string,
) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return categoryName?.toLowerCase() === DRINKS
        ? REGULAR_SIZE.upper_case
        : HALF_SIZE.upper_case;
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName).upper_case;
    default:
      break;
  }
};

export const isZeroOnStart = (value) => {
  const valueArray = value && value?.split('');
  return valueArray?.length > 0 && valueArray[0] <= 0;
};

export const delayer = (func, time = 50) => {
  let isRun = false;
  return () => {
    if (isRun === false) {
      func();
      isRun = true;
      setTimeout(() => {
        isRun = false;
      }, time);
    }
  };
};
export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const SendAQuoteMapper = (
  customer,
  receiver,
  location,
  cart,
  paymentMethod,
  giftCard,
  cartPricing,
) => {
  return {
    items: cart?.items?.map((item) => ({
      ...item,
      total_net_price: item.display_price,
    })),

    payment_details: [
      {
        amount: paymentMethod[0]?.amount,
        // is_processed: 0,
        tip_amount: cartPricing?.tip,
        // is_cancelled: 0,
        // is_failed: 0,
        // is_refund: 0,
        // is_gifted: 0,
        // is_splitted: false,
        // is_free: false,
        // is_removed: 0,
        // processed_at: null,
        order_payment_type: 'Card',
        card: {
          ...paymentMethod?.[0],
          card_name: paymentMethod?.[0]?.name,
        },
        gift_card: giftCard,
        house_account: [],
      },
    ],
    tip: cartPricing?.tip,
    discount: cartPricing?.discount,
    delivery_fee: cartPricing?.delivery_fee,
    service_fee: cartPricing?.service_fee,
    cz_dollar:
      cartPricing?.cz_dollar && cartPricing?.cz_dollar > 0
        ? `- ${cartPricing?.cz_dollar}`
        : null,

    //sub-total
    gross_sales: cartPricing?.sub_total,
    sub_total: cartPricing?.sub_total,
    tax: cartPricing?.tax,
    total_price: cartPricing?.total_price,
    tax_exemption:
      cartPricing?.tax_exemption && cartPricing?.tax_exemption > 0
        ? `- ${cartPricing?.tax_exemption}`
        : null,

    customer_delivery_address:
      cart?.orderDetails?.delivery_address?.full_address,
    customer_delivery_city: cart?.orderDetails?.delivery_address?.city,
    customer_delivery_state: cart?.orderDetails?.delivery_address?.state,
    customer_delivery_street_1: cart?.orderDetails?.delivery_address?.street,
    customer_delivery_zipcode: cart?.orderDetails?.delivery_address?.zipcode,

    promise_time: cart?.orderDetails?.PickupSlotDetails?.date
      ? `${cart?.orderDetails?.PickupSlotDetails?.date} ${cart?.orderDetails?.PickupSlotDetails?.time}`
      : '',

    order_details: cart?.orderDetails,

    customer_id: customer?.id,
    customer_email: customer?.email,
    customer_first_name: customer?.first_name,
    customer_full_name: customer?.full_name,
    customer_last_name: customer?.last_name,
    customer_phone: customer?.phone,

    receiver_email: receiver?.email,
    receiver_first_name: receiver?.first_name,
    receiver_full_name: receiver?.full_name || '',
    receiver_last_name: receiver?.last_name || '',
    receiver_phone: receiver?.phone || '',

    is_catering: 1,
    order_type:
      cart?.orderType === PICK_UP_ORDER_TYPE ? PICK_UP_LABEL : DELIVERY_LABEL,
    platform: 'Web',

    store_id: location?.selectedStore?.id,
    store_address: location?.selectedStore?.address,
    store_city: location?.selectedStore?.city,
    store_name: location?.selectedStore?.name,
    store_phone: location?.selectedStore?.phone,
    store_state: location?.selectedStore?.state,
    store_zip: location?.selectedStore?.zip,
  };
};

export const saveDraftMapper = ({
  customer,
  receiver,
  location,
  cart,
  wizard,
  checkout,
  specificFlowState,
  user,
}) => {
  return {
    draft_name: '',
    customer_id: user,
    cart_id: cart?.items[0]?.cart_id ?? '',
    receiving_customer: receiver ?? {},
    search_by_value: location.searchByValue ?? '',
    location_id: location?.selectedStore?.id ?? '',
    order_type: cart.orderType ?? '',
    delivery_address: {
      id: cart?.orderDetails?.delivery_address?.id ?? '',
      customer_id: cart?.orderDetails?.delivery_address?.customer_id ?? '',
      is_default: cart?.orderDetails?.delivery_address?.is_default ?? '',
      value: cart?.orderDetails?.delivery_address?.value ?? '',
      label: cart?.orderDetails?.delivery_address?.label ?? '',
      street: cart?.orderDetails?.delivery_address?.street ?? '',
      street_name: cart?.orderDetails?.delivery_address?.street_name ?? '',
      city: cart?.orderDetails?.delivery_address?.city ?? '',
      state: cart?.orderDetails?.delivery_address?.state ?? '',
      zip_code: cart?.orderDetails?.delivery_address?.zipcode ?? '',
      apartment_no: cart?.orderDetails?.delivery_address?.apartment_no ?? '',
      full_address: cart?.orderDetails?.delivery_address?.full_address ?? '',
      address_name: cart?.orderDetails?.delivery_address?.address_name ?? '',
    },
    pickup_slot: {
      order_business_date: cart?.orderDetails?.PickupSlotDetails?.date ?? '',
      order_promise_time: cart?.orderDetails?.PickupSlotDetails?.time ?? '',
      order_pickup_method: cart?.orderDetails?.PickupSlotDetails?.pickupMethod,
    },
    tip: {
      tip_type: checkout?.deliveryTipObject?.type ?? '',
      tip_amount: checkout?.deliveryTipObject?.tip ?? '',
    },
    tax_exempt_id: '',
    payment: {
      payment_method: [...checkout?.paymentMethods],
      gift_card: [...checkout?.giftCard],
      is_split_payment: checkout?.isSplitPayment,
      house_account: checkout.houseAccountObject,
      tax_exempt_account: checkout.taxExemptAccountObject,
      address_name: cart?.orderDetails?.delivery_address?.address_name ?? '',
      cart_details: {
        ...cart,
        user_offer_id: null,
        root_offer_id: null,
        eligible_item_offer_id: null,
        offerDiscount: 0,
        offerDiscountType: null,
        free_delivery: false,
      },
      delivery_toggle: location.isDeliveryToggle,
      total_price: checkout?.totalAmount,
    },
    wizard: {
      active_step: wizard.activeStep,
      current_step: wizard.currentStep,
      last_active_step: wizard.lastActiveStep,
      active_line_width: wizard.activeLineWidth,
      revisitable_steps: wizard.revisitAbleSteps,
    },
    primary_capacity: {
      primary_capacity_item:
        specificFlowState?.primaryCapacity?.primaryCapacityItem ?? '',
      number_of_guest: specificFlowState?.primaryCapacity?.guests ?? '',
    },
    location_order_types: {
      delivery: [
        ...(location?.selectedStore?.order_types?.Delivery
          ? location?.selectedStore?.order_types?.Delivery
          : []),
      ],
      pick_up: [
        ...(location?.selectedStore?.order_types?.Pickup
          ? location?.selectedStore?.order_types?.Pickup
          : []),
      ],
    },
  };
};

export function findKeyWithGroupId(obj:any, groupId:any) {
  function checkModifiers(modifiers:any) {
      return modifiers.some((modifier:any) => modifier.modifier_group_id === groupId);
  }

  for (const key in obj) {
      if (obj[key] && Array.isArray(obj[key].modifiers) && checkModifiers(obj[key].modifiers)) {
          return key;
      }
  }

  return null;
}

