import { Ref, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { Field, Formik } from 'formik';
import CustomModal from 'src/components/customModal/CustomModal';
import FormField from 'src/components/FormFields/FormField';
import { PHONE_NUMBER_MASK } from 'src/constants';

import styles from './customerInfo.module.scss';

interface BookingCustomerInfoProps {
  isReadonly?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isModalOpen?: boolean;
  onSubmitLoading?: boolean;
  requiredPhone?: boolean;
  lessPhone?: boolean;
  submitRef?: React.MutableRefObject<undefined>;
  handleSubmit?: Function;
  handldeClose?: any;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

const BookingCustomerInfo = ({
  isReadonly = false,
  firstName,
  lastName,
  email,
  phone,
  requiredPhone = false,
  lessPhone = false,
  isModalOpen,
  onSubmitLoading,
  submitRef,
  handleSubmit,
  handldeClose,
}: BookingCustomerInfoProps) => {
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const handleCloseEmailModal = () => setOpenEmailModal(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleShowEmailModal = (event) => {
    setOpenEmailModal(event.target.value);
  };

  const validateForm = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    // Validate a required field
    if (!values?.first_name) {
      errors.first_name = 'First name is required';
    }

    // Validate a required field
    if (!values?.last_name) {
      errors.last_name = 'Last name is required';
    }

    // Validate an email field
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (
      !emailRegex.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    // validate the phone field
    if (requiredPhone && !values.phone) {
      errors.phone = 'Phone is required';
    }

    if (requiredPhone && values?.phone) {
      const numbers = values?.phone?.toString()?.match(/\d+/g);
      if (numbers?.[0]?.length < 10)
        errors.phone = 'Please enter the complete phone number';
    }

    if (lessPhone && values.phone?.toString()?.length > 0) {
      const numbers = values?.phone?.toString()?.match(/\d+/g);
      if (numbers?.[0]?.length < 10)
        errors.phone = 'Please enter the complete phone number';
    }
    return errors;
  };

  return (
    <section className={styles.customerInfoWrap}>
      <Formik
        validateOnChange={true}
        validateOnMount={true}
        initialValues={{
          first_name: firstName ?? '',
          last_name: lastName ?? '',
          email: email ?? '',
          phone: phone ?? '',
        }}
        validate={validateForm}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        innerRef={submitRef}
      >
        {({
          values,
          errors,
          setErrors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
        }) => {
          return (
            <>
              <form className="new_form_design" onSubmit={handleSubmit}>
                <div className={isModalOpen ? 'px-4' : ''}>
                  <div className={`${styles.customerInfoFields} d-flex mb-3`}>
                    <div className="form-group">
                      <FormField
                        name="first_name"
                        labelText="First Name"
                        isRequired
                        type="text"
                        placeholder="First Name"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        readonly={isReadonly}
                        value={values['first_name']}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <FormField
                      name="last_name"
                      labelText="Last Name"
                      isRequired
                      type="text"
                      placeholder="Last Name"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      readonly={isReadonly}
                      value={values['last_name']}
                    />
                  </div>
                  <div className="form-field mb-3">
                    <FormField
                      name="email"
                      labelText="Email"
                      isRequired
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      // onClick={handleShowEmailModal}
                      errors={errors}
                      touched={touched}
                      readonly={isReadonly}
                      value={values['email']}
                    />
                  </div>
                  <div className="form-field mb-3">
                    <label htmlFor="phone" className="f-s16 d-flex">
                      Phone Number{' '}
                      <span
                        style={{
                          color: '#ed4d4d',
                          fontSize: '20px',
                          height: '20px',
                        }}
                      >
                        {requiredPhone && <>*</>}
                      </span>
                    </label>
                    <div className="disabledField">
                      <Field
                        name="phone"
                        render={({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={PHONE_NUMBER_MASK}
                            id="phone"
                            // readOnly
                            disabled={isReadonly && requiredPhone}
                            placeholder="(223) 123-1234"
                            type="text"
                            value={values.phone}
                            // readonly={isReadonly}
                            onChange={(e) => {
                              // if (requiredPhone || phone.toString().length > 0)
                              // !isReadonly &&
                              setFieldValue(
                                'phone',
                                e.target.value.replace(/[() -]/g, ''),
                              );
                            }}
                            guide={true}
                            className={`
                          ${errors.phone && touched.phone
                                ? 'input_error'
                                : 'text-input'
                              }
                            form-control`}
                          />
                        )}
                      />
                    </div>
                    {errors.phone && touched.phone && (
                      <div className="input-feedback clr-dark-red f-s12 font-plex mt-1 d-inline-block">
                        {errors.phone}
                      </div>
                    )}
                  </div>
                </div>
                {isModalOpen ? (
                  <div className="d-flex justify-content-end mt-4 border-top py-3 gap-4 pe-4">
                    <button
                      type="button"
                      className=" solid-border-btn text-color f-w6 f-s14 text-uppercase py-1 text-uppercase"
                      onClick={handldeClose}
                    >
                      Do another search
                    </button>
                    <button
                      type="submit"
                      className="global-filled-btn clr-white text-uppercase"
                      disabled={onSubmitLoading || isSubmitting}
                    >
                      {onSubmitLoading ? 'Registering...' : 'Complete Sign Up'}
                    </button>
                  </div>
                ) : null}
              </form>
            </>
          );
        }}
      </Formik>
      <CustomModal
        modalDialogClasses={'defaultModalwrapper'}
        showModal={openEmailModal}
        title={'Attach New Customer'}
        closeModal={handleCloseEmailModal}
      >
        <div className={`modalBottmBorder p-4`}>
          <p className="font-inter f-s18 f-w4 light-blue">
            Are you sure you want to attach new customer to this order?
          </p>
          <span className="text-color f-s16 f-w4 ">
            Doing so will clear the current Receiving Customer details and you
            fill them again.
          </span>
        </div>
        <div className="d-flex gap-3 justify-content-end p-3">
          <button type="button" className="solid-border-btn">
            No
          </button>
          <button type="button" className="global-filled-btn clr-white">
            Yes
          </button>
        </div>
      </CustomModal>
    </section>
  );
};

export default BookingCustomerInfo;
