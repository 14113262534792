import React, { useEffect, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { SearchIcon } from 'src/assets/images/Svgs/svg';
import { useDispatch } from 'react-redux';
import {
  updateSearchQuery,
  removeSearchQuery,
} from 'src/Features/AccountSetup/redux/slices';
import { Toast_Func } from 'src/helper/toast.helper';
import useOperatorCustomerList from 'src/react-query-hooks/Operator/useOperatorCustomerList';

import styles from './customerInfo.module.scss';
import { ROUTE_STEP_1 } from 'src/constants';
interface SearchedCustomerProps {
  handleSelectCustomer: Function;
  searchQuery: string;
  handleSearchQuery: Function;
  isLoading: boolean;
  searchUser: boolean;
  customersList: any;
  handleSearch: () => void;
}

const SearchedCustomer = ({
  handleSelectCustomer,
  searchQuery,
  handleSearchQuery,
  isLoading,
  searchUser,
  customersList,
  handleSearch,
}: SearchedCustomerProps) => {
  // const {
  //   data: customersList,
  //   refetch,
  //   isLoading,
  // } = useOperatorCustomerList({
  //   query: searchQuery,
  // });
  // const loginDispatch = useDispatch();
  // const [searchUser, setSearchedUser] = useState(false);
  // const handleSearch = async () => {
  //   setSearchedUser(true);
  //   loginDispatch(updateSearchQuery(searchQuery));
  //   const { data } = await refetch();
  //   if (data?.length == 1) {
  //     handleSelectCustomer(data[0].id);
  //     sessionStorage.setItem('noUserFound', String(false));
  //     setSearchedUser(false);
  //     loginDispatch(removeSearchQuery());
  //   }

  //   if (data?.length == 0) {
  //     handleNoUserFound(true);
  //     Toast_Func({ status: true, message: 'No user found.' });
  //     sessionStorage.setItem('noUserFound', String(true));
  //     setSearchedUser(false);
  //   }
  //   if (data?.length > 0) {
  //     setSearchedUser(false);
  //   }
  // };

  const handleKeyPress = (event) => {
    if (!searchQuery?.trim()?.length) return;
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   const urll = new URL(currentUrl);
  //   const params = new URLSearchParams(urll.search);
  //   const phoneNumber = params.get('phone');
  //   const store = params.get('store');

  //   if (phoneNumber?.length && searchQuery?.length) {
  //     handleSearch();
  //     window.history.replaceState(
  //       null,
  //       '',
  //       `${ROUTE_STEP_1}?store=${store || ''}`,
  //     );
  //   }
  // }, [searchQuery]);
  return (
    <>
      <div className={`py-3 ${styles.searchedCustomerEmails}`}>
        <h2 className="py-3 text-center mb-0 font-inter f-s20 light-blue f-w5">
          Look Up Guest To Start An Order
        </h2>
        <div className={`${styles.EmailSearchInput}`}>
          <input
            placeholder="Search by Email, Phone or Name"
            type="text"
            onChange={(e) => {
              const value = e.target.value?.replace(/['"]/g, '');
              handleSearchQuery(value || '');
            }}
            onKeyDown={handleKeyPress}
            value={searchQuery}
            name=""
            id=""
            className="font-plex light-blue"
          />
          <button
            onClick={handleSearch}
            disabled={searchQuery?.trim().length === 0}
            className="d-flex justify-content-center align-items-center h-100"
          >
            {searchUser || isLoading ? (
              <Spinner
                animation="border"
                variant="dark"
                className={styles.spinner}
              />
            ) : (
              <SearchIcon />
            )}
          </button>
        </div>
        <hr className="mb-3 mt-4 customHr" />
        {customersList?.length ? null : (
          <h3 className="text-center mb-0 font-inter f-s20 light-blue f-w5">
            Your search results will go here...
          </h3>
        )}
        {customersList?.length ? (
          <div className={`${styles.EmailsLists}`}>
            <div className={styles.searchedCustomerWrap}>
              <>
                <div className={styles.customerInfoModalContent}>
                  <div className="table-responsive">
                    <div className="table-div">
                      <Table
                        className={`light-blue font-plex tableRadius-4 ${styles.listing_table}`}
                      >
                        <thead>
                          <tr className={styles.header_row}>
                            <th>
                              <div>
                                NAME<div></div>
                              </div>
                            </th>
                            <th className="">
                              <div>EMAIL</div>
                            </th>
                            <th className="">
                              <div>PHONE</div>
                            </th>
                            <th className="">
                              <div>PLATFORM</div>
                            </th>
                            <th className="">
                              <div>SIGN UP DATE</div>
                            </th>
                            <th className="">
                              <div>ACTION</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {customersList?.map((customer) => (
                            <tr>
                              <td>
                                <p className="mb-0 f-w4">
                                  {customer.full_name}
                                </p>
                              </td>
                              <td>
                                <p className="mb-0 f-w4">{customer.email}</p>
                              </td>
                              <td>
                                <p className="mb-0 f-w4"> {customer.phone}</p>
                              </td>
                              <td>
                                <p className="mb-0 f-w4">{customer.platform}</p>
                              </td>
                              <td>
                                <p className="mb-0 f-w4">
                                  {customer.signup_date}
                                </p>
                              </td>
                              <td>
                                <button
                                  className="font-plex f-s16 f-w5 clr-white"
                                  onClick={() =>
                                    handleSelectCustomer(customer.id)
                                  }
                                >
                                  Select
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SearchedCustomer;
