import React from 'react';
import { Modal } from 'react-bootstrap';

interface ItemRemoveModalProps {
  showModal: boolean;
  loading: boolean;
  handleClose: () => void;
  removeItem: () => void;
}
const ItemRemoveModal: React.FC<ItemRemoveModalProps> = ({
  showModal,
  handleClose,
  removeItem,
  loading,
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="theme-modal-style app_container_wrap no-padding border-radius-8 no-border defaultModalwrapper"
      >
        <Modal.Header className="pt-0" id="close_button2">
          <button
            type="button"
            className="btn border-0 modal-close-button pe-0 light-blue-icon"
            onClick={handleClose}
          >
            Close
          </button>
          <h6 className="font-inter ls-normal mb-0 f-s18 light-blue">Remove the Item</h6>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <p className="f-s16 text-start f-w4 light-blue font-inter mb-0 px-3 py-4 modalBottmBorder">
          Are you sure you want to remove this item from your order?
          </p>
          <div className="text-center d-flex justify-content-end gap-4 p-4">
          <button
              type="button"
              onClick={handleClose}
              className="cancel-filled-btn f-s14 f-w5 font-inter text-uppercase"
            >
              No, Keep the order
            </button>
            <button
              type="button"
              className="global-filled-btn font-inter f-s14 f-w5 font-inter clr-white text-uppercase"
              onClick={removeItem}
              id={'remove_item_button'}
              disabled={loading}
            >
              yes, Remove
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemRemoveModal;
