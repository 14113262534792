import { getUser } from 'src/helper/helperMethods';
import QRCodeCanvas from 'qrcode.react';
import useGetGiftCardNumber from 'src/react-query-hooks/Loyalty/useGetGiftCardNumber';

interface RedeemDollarsModalProps {
  header: string;
  title: string;
  description: string;
  qrCodeValue: string;
}

const RedeemDollarsModal = ({
  header,
  title,
  description,
  qrCodeValue,
}: RedeemDollarsModalProps) => {
  return (
    <div className="pt-3">
      <h2 className="font-Cls f-s22 text-center text-uppercase lh-base clr-dar-gray">
        {header}
      </h2>
      <p className="font-inter f-s16 text-center text-uppercase lh-base light-blue">
        <span className="f-w8">{title}</span>
      </p>
      <p className="font-inter f-s14 text-center text-uppercase lh-base light-blue mt-3">
        {description}
      </p>
      <div className="text-center cz-mt-60 cz-mb-40">
        <QRCodeCanvas value={qrCodeValue} size={200} />
      </div>
      <hr />
    </div>
  );
};
export default RedeemDollarsModal;
