import React from 'react';
import { useDispatch } from 'react-redux';
import { EditPencilIcon, TrashIcon } from 'src/assets/images/Svgs/svg';

import { itemBuckets } from '../../../priceCalculation/buckets';
import {
  removeTemporarilyAddedPriceForItem,
  resetOrderInBucket,
} from '../../../redux/slices/itemCustomizationSlice';

interface ISelectedItemsBox {
  orderedIngredients: any[];
  isItMobile: boolean;
  showModifierModal: () => void;
}

const ItemsBox: React.FC<ISelectedItemsBox> = (props) => {
  const { orderedIngredients = [], isItMobile, showModifierModal } = props;
  const dispatch = useDispatch();

  const resetHandler = () => {
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.removeAddedModifiersInCreateYourOwnSalad(1),
        item: 1,
      }),
    );
    dispatch(resetOrderInBucket({ itemNo: '1' }));
    itemBuckets.removeModifiersfromBucket(1);
  };

  return (
    <React.Fragment>
      <div className="bg-white rounded-3 modifiers-box text-start py-4 ps-4 pe-md-4 pe-2 my-4 position-relative">
        {isItMobile ? (
          <span className="d-flex justify-content-between position-absolute right-20 end-0 pe-4">

            <button
              type="button"
              className="btn text-decoration-underline p-0 ms-3 f-s14 remove-action-btn clr-dark-red"
              onClick={resetHandler}
            >
              <TrashIcon />
            </button>
            <button
              type="button"
              className="btn text-decoration-underline p-0 ms-3 f-s14 edit-action-btn clr-dark-red"
              onClick={showModifierModal}
            >
              <EditPencilIcon />
            </button>
          </span>
        ) : null}
        <div className="modifiers-scroll">
          {orderedIngredients?.map((item) => {
            return (
              <div
                className="font-Visby-cf f-s14 fw-bold lh-lg"
                key={item.modifier_id}
              >
                {item?.modifier_name}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment >
  );
};

export default ItemsBox;
