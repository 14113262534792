import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import Button from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';
import Loader from 'src/components/Loader/Loader';
import MultiTagSelect from 'src/components/MultiTagSelect/MultiTagSelect';
import { Toast_Func } from 'src/helper/toast.helper';
import { SendAQuoteMapper } from 'src/helper/utils';
import useProfile from 'src/react-query-hooks/useProfile';
import useSendQuote from 'src/react-query-hooks/useSendAQuote';
import { useAppSelector } from 'src/redux/store/store';

import { API_ERROR_MESSAGE } from '../../../models/error.model';

import styles from './billing.module.scss';
import ToggleButton from 'src/components/ToggleButton/ToggleButton';

type ItemDetailForm = {
  name: string[];
  reset: boolean;
};

const SendQuoteModal = ({
  closeSendQuoteModal,
  props,
  setQuoteSentModal,
  setSentEmailTo,
  setDraftModal,
  applyCzDollar,
  setApplyCZDollar,
  defaultGiftCard,
  isHouseAccount,
}) => {
  const { user: authInfo } = useAppSelector((state) => state.user);
  const receivingUser = useAppSelector((state) => state.user?.recievingUser);
  const bookingUser = useAppSelector((state) => state.user?.bookingUser);
  const user = authInfo.id;
  const { data } = useProfile(user, authInfo.type);

  const location = useAppSelector((state) => state.location);
  const cart = useAppSelector((state) => state.cart);
  const payment_methods = useAppSelector(
    (state) => state?.checkout?.paymentMethods,
  );
  const giftCard = useAppSelector((state) => state?.checkout?.giftCard);
  const [sendToReceiver, setSendToOperator] = useState(true);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState<boolean>(false);

  const [formState, setFormState] = useState<ItemDetailForm>({
    name: [],
    reset: false,
  });
  const { mutateAsync: sendQuote } = useSendQuote();

  const handleFromFields = (formStates): void => {
    setFormState({ ...formStates });
  };

  const sendAQuote = async (item: any, isSendQuote: Boolean, dataprops: any) => {
    const dataMapper = SendAQuoteMapper(
      bookingUser || data,
      receivingUser,
      location,
      cart,
      payment_methods,
      giftCard,
      dataprops,
    );
    sendQuote(
      {
        ...dataMapper,
        send_to: item,
        // this needs to be replaced with the customer_email
        customer_email: data?.email,
      },
      {
        onSuccess: (data, variables, context) => {
          setLoading(false);
          setIsLoadingDraft(false);
          isSendQuote ? setQuoteSentModal(true) : setDraftModal(true);
          closeSendQuoteModal();
          setApplyCZDollar(false);
          Toast_Func({ status: true, message: 'Email successfully sent' });
        },
        onError: (error, variables, context) => {
          setLoading(false);
          setIsLoadingDraft(false);
          setQuoteSentModal(false);
          console.error('Error submitting the form:', error);
          const {
            data: { message },
          } = (error as API_ERROR_MESSAGE).response;
          Toast_Func({ status: false, message });
        },
      },
    );
  };

  const debouncedSendQuote = useCallback(_debounce(sendAQuote, 500), []);

  const handleCloseQuoteSentModal = (isSendQuote, dataprops) => {
    const send_to = [...formState.name];
    // Check if "operator" checkbox is checked and add the operator email
    if (sendToReceiver && receivingUser?.email) {
      //this needs to replaced
      send_to.push(receivingUser?.email || '');
    }
    setSentEmailTo(send_to);

    debouncedSendQuote(send_to.join(','), isSendQuote, dataprops);
    isSendQuote ? setLoading(true) : setIsLoadingDraft(true);
  };

  return (
    <>
      <div className={`px-4 pt-3 ${styles.sendQuoteModalWrapper}`}>
        <div>
          <div className="mb-3">
            This quote will be sent to the booking customer email
          </div>
          <div className="form-group theme_check_boxes">
            <input
              name="operator"
              type="checkbox"
              id="operator"
              checked={sendToReceiver}
              onChange={() => setSendToOperator(!sendToReceiver)}
            />
            <label
              htmlFor="operator"
              className="d-flex align-items-center f-s16 light-blue f-w4 pt-0 mb-4"
            >
              <span className="box_wrap"></span>{' '}
              {`Send quote to receiving customer at `}
              <b> &nbsp; {receivingUser?.email}</b>
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 user-select-none">
          <h3 className="f-s16 f-w4 light-blue  mb-2">
            Send quote to additional recipients’ Emails (separated by commas):
          </h3>
        </div>
        <div className="new_form_design">
          <MultiTagSelect
            formState={formState}
            handleFromFields={handleFromFields}
            fontSizeLabel={'f-s16'}
            placeholder={'Enter Email'}
            limitAdditionalValue={true}
          />
        </div>
        {!isHouseAccount && defaultGiftCard?.balance > 0 && (
          <div className="d-flex pb-2">
          <ToggleButton
            text={"Use CZ Dollars?"}
            setSplitPayment={setApplyCZDollar}
            isSplitPayment={applyCzDollar}
            checked={undefined}
          />
        </div>
        )}
        
        
        <div>
          <ul className="list-inline">
            <li className="d-flex justify-content-between py-2">
              <div className="font-plex light-blue f-s14 f-w5">Subtotal </div>
              <span className="d-inline-block ms-3 f-s14 f-w5 font-plex light-blue">
                {`$${props.sub_total}`}
              </span>
            </li>
            {props.discount && props.discount > 0 && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Discount </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`- $${props.discount}`}
              </span>
            </li>)}
            {props.tip && props.tip > 0 && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Tip </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`$${props.tip}`}
              </span>
            </li>)}
            {props.tax && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Tax </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`$${props.tax}`}
              </span>
            </li>)}
            {props.tax_exemption && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Tax Exemption </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`- $${props.tax_exemption}`}
              </span>
            </li>)}
            {props.delivery_fee && props.delivery_fee > 0 && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Delivery fee </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`$${props.delivery_fee}`}
              </span>
            </li>)}
            {props.service_fee && props.service_fee > 0 && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">Service fee </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`$${props.service_fee}`}
              </span>
            </li>)}
            {props?.cz_dollar && props?.cz_dollar > 0 && (<li className="d-flex justify-content-between py-2">
              <div className="font-inter light-blue f-s14 f-w4">CZ Dollar </div>
              <span className="d-inline-block ms-3 f-s14 f-w4 font-inter light-blue">
                {`- $${props.cz_dollar}`}
              </span>
            </li>)}
            <li className="d-flex justify-content-between py-2">
              <div className="font-plex light-blue f-s14 f-w7">
                {' '}
                Total
              </div>
              <span className="d-inline-block ms-3 font-plex light-blue f-s14 f-w7">
                {`$${props.total_price}`}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex gap-4 justify-content-end px-3 border-top py-4">
        <button
          onClick={()=> {
            setApplyCZDollar(false);
            closeSendQuoteModal()
          }}
          type="button"
          className="cancel-filled-btn"
        >
          Cancel
        </button>

        <Button
          disabled={isLoadingDraft || isLoading || formState?.name?.length > 5}
          onClick={() => {
            handleCloseQuoteSentModal(false, props);
          }}
          type="button"
          className="global-filled-btn clr-white"
          loading={isLoadingDraft}
        >
          Send and Save Draft
        </Button>

        <Button
          disabled={isLoadingDraft || isLoading || formState?.name?.length > 5}
          onClick={() => {
            handleCloseQuoteSentModal(true, props);
          }}
          type="button"
          className="global-filled-btn clr-white"
          loading={isLoading}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default SendQuoteModal;
