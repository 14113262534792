import React from 'react';
import CounterCard from 'src/components/Molecules/Card/CounterCard/CounterCard';
import SelectCard from 'src/components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from 'src/constants';

import { verifyStatus } from '../../../../helper/customizedItem/customizedItem.helper';

const ItemModifierGroups: React.FC<any> = (props) => {
  const {
    ingredients,
    selectedAddOns,
    selectedCore,
    handleClick,
    isLimitExceed,
    isItEdit,
    noGrid,
    showCustomization,
    itemModifierItemModalWidth,
    cardColSize,
    Item,
  } = props;

  const selectedCoreModifier = (ingredient) => {
    return selectedCore?.find(
      (selected) => selected?.modifier_id === ingredient.id,
    );
  };

  const selectedAddonsModifier = (ingredient) => {
    return selectedAddOns?.find(
      (selected) => selected.modifier_id === ingredient.id && !selected.core,
    );
  };

  const selectedModifier = (ingredient) => {
    //
    let selectedSide = selectedAddonsModifier(ingredient);
    if (!selectedSide) {
      selectedSide = selectedCoreModifier(ingredient);
    }
    return selectedSide;
  };

  const modifierGroupLabels = (
    selectedSide: any,
    extendable_limit: number,
    groupMin: number,
  ) => {
    if (extendable_limit === 1 && groupMin === 1) {
      return selectedSide ? 'Included' : 'include';
    } else {
      return undefined;
    }
  };

  return (
    <>
      {ingredients?.modifiers_groups_modifiers.map(
        (ingredient, index: number) => {
          const selectedSide = selectedModifier(ingredient);
          return (
            <React.Fragment key={index}>
              <div
                className={`${
                  noGrid ? 'item__modifier_item' : 'mb-3'
                } ${itemModifierItemModalWidth} px-2 ${cardColSize} col-4 col-md-3 col-lg-3 ${
                  Item?.is_box_lunch && 'col-lg-3 px-2'
                }`}
              >
                {ingredient.max > 1 ? (
                  <CounterCard
                    id={ingredient.id}
                    brink_id={ingredient.brink_modifier_id}
                    default_selected={ingredient.is_selected}
                    image={`${Constants.s3BaseUrl}${ingredient?.image}`}
                    title={ingredient.name}
                    calories={ingredient.calories}
                    price={ingredient.price}
                    is_active={verifyStatus(
                      ingredient,
                      Constants.MODIFIER_LOCATION,
                    )}
                    priceAlternateLabel={modifierGroupLabels(
                      selectedSide,
                      ingredients.extendable_limit,
                      ingredients.min,
                    )}
                    handleClick={(data) => {
                      handleClick(data, ingredients, Constants?.ADD_ONS);
                    }}
                    max={ingredient.max}
                    groupMin={ingredients.min}
                    count={selectedSide?.quantity || 0}
                    isApplyDisable={true}
                    additionalPrice={selectedSide?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      ingredients.id,
                      ingredients.extendable_limit,
                      ingredients.min,
                    )}
                    extendableLimitValue={ingredients.extendable_limit}
                    isItEdit={showCustomization}
                  />
                ) : (
                  <SelectCard
                    id={ingredient?.id}
                    brink_id={ingredient?.brink_modifier_id}
                    default_selected={ingredient.is_selected}
                    image={`${Constants.s3BaseUrl}${ingredient?.image}`}
                    title={ingredient.name}
                    price={ingredient.price}
                    calories={ingredient.calories}
                    is_active={verifyStatus(
                      ingredient,
                      Constants.MODIFIER_LOCATION,
                    )}
                    handleClick={(data) => {
                      handleClick(data, ingredients, Constants?.ADD_ONS);
                    }}
                    priceAlternateLabel={modifierGroupLabels(
                      selectedSide,
                      ingredients.extendable_limit,
                      ingredients.min,
                    )}
                    groupMin={ingredients.min}
                    selected={Boolean(selectedSide)}
                    modifierAdditionalPrice={selectedSide?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      ingredients.id,
                      ingredients.extendable_limit,
                      ingredients.min,
                    )}
                    extendableLimitValue={ingredients.extendable_limit}
                    isItEdit={isItEdit}
                    showCustomization={showCustomization}
                  />
                )}
              </div>
            </React.Fragment>
          );
        },
      )}
    </>
  );
};

export default ItemModifierGroups;
