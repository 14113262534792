import React from 'react';
import { Modal } from 'react-bootstrap';

import { IAddAndRemoveModalProps } from '../../../models/try2Combo';
import { itemBuckets } from '../../../priceCalculation/buckets';
import { resetMadedTryTwoComboItemData } from '../../../redux/slices/itemCustomizationSlice';
import { useAppDispatch } from '../../../redux/store/store';

const AddAndRemoveModal: React.FunctionComponent<IAddAndRemoveModalProps> = (
  props,
) => {
  const {
    showAddAndRemoveModal,
    hideAddAndRemoveModal,
    id,
    name,
    onRemoveClickHandler,
    onEditClickHandler,
    isCustomizeable,
  } = props;
  const dispatchItemCustomization = useAppDispatch();
  const clickHanlder = () => {
    dispatchItemCustomization(resetMadedTryTwoComboItemData());
    onRemoveClickHandler(id);
    itemBuckets.resetSpecificComboBucket(id);
    hideAddAndRemoveModal();
    // dispatch(removeSpecificItem({name:id}))
  };
  const clickEditHanlder = () => {
    onEditClickHandler(name, id);
    hideAddAndRemoveModal();
    // dispatch(removeSpecificItem({name:id}))
  };
  return (
    <Modal
      show={showAddAndRemoveModal}
      onHide={hideAddAndRemoveModal}
      centered
      className="theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 defaultModalwrapper"
    >
      <Modal.Header className="pt-0" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 light-blue-icon"
          onClick={hideAddAndRemoveModal}
        >
          Close
        </button>
        <h6 className="font-plex ls-normal mb-0 f-s18 light-blue">Edit or Remove</h6>
      </Modal.Header>
      <Modal.Body className="text-center py-5">
        <p className="f-s16 text-start f-w4 light-blue font-plex mb-0 px-4 py-5 modalBottmBorder">
          Would you like to edit or remove this selected item?
        </p>
        <div className="text-center d-flex justify-content-end gap-4 p-3">
        <button
            type="button"
            className="cancel-filled-btn f-s14 f-w5 font-inter text-uppercase"
            onClick={clickHanlder}
          >
            Remove
          </button>
          <button
            type="button"
            className="global-filled-btn font-inter f-s14 f-w5 font-inter clr-white text-uppercase"
            onClick={clickEditHanlder}
            disabled={isCustomizeable}
          >
            Edit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAndRemoveModal;
