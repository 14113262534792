import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { ADD_ONS, DECREASE, DO_NOT_SHOW } from 'src/constants';
import styleClasses from '../../../../src/Features/ItemCustomization/ItemCustomization.module.scss';
import ItemModifierGroups from './ModifierGroups/ItemModifierGroups';
import { findKeyWithGroupId } from 'src/helper/utils';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { addExtendableLimit } from 'src/helper/helperMethods';
import { Codes } from 'src/models/item.model';
import { cloneDeep } from 'lodash';

const ModifierGroups: React.FC<any> = (props) => {
  const {
    modifierGroups,
    selectedAddOns,
    selectedCore,
    isLimitExceed,
    handleClick,
    isItEdit,
    cardColSize,
    isBoxLunch,
    Item,
    sectionSelected,
    isPackege = false,
    showResetButton,
  } = props;
  const showGroup = (ingredients) => {
    if (ingredients?.name?.toLowerCase().includes(DO_NOT_SHOW)) {
      return false;
    } else {
      return true;
    }
  };
  const handleResetPayload = (type: string, quantity: any, item: any) => {
    let payload: any = {
      modifier_id: item.modifier_id,
      modifier_name: item.modifier_name,
      display_price: item.display_price,
      modifier_calories: item.modifier_calories,
      brink_id: item.brink_id,
      quantity: quantity,
      modifier_group_min: item.modifier_group_min,
      max: item.max,
      type: type,
      is_selected: item.is_selected,
      code: Codes.NO,
    };
    payload = addExtendableLimit(payload, type, item.extendableLimitValue);
    return payload;
  };
  // handleClick(data, ingredients, Constants?.ADD_ONS);
  const handleResetButton = (ingredient: any) => {
    const keyValue = findKeyWithGroupId(
      itemBuckets.getBuckets()[sectionSelected - 1]?.bucket,
      ingredient.id,
    );
    if (!keyValue) return;
    const bucketData = itemBuckets.getBuckets();
    const copyData = [
      ...bucketData[sectionSelected - 1]?.bucket[keyValue]?.modifiers,
    ];
    for (let index = 0; index < copyData.length; index++) {
      const singleItem = cloneDeep(copyData[index]);
      if (singleItem?.modifier_group_id === ingredient.id) {
        for (let i = 1; i <= singleItem.quantity; i++) {
          const data = handleResetPayload(
            DECREASE,
            singleItem.quantity - i,
            singleItem,
          );
          handleClick(data, ingredient, ADD_ONS);
        }
      }
    };
  };
  return (
    <>
      {modifierGroups &&
        modifierGroups.map((ingredients, index) => {
          const label = ingredients?.label?.toLowerCase();
          const showButtonOfReset =
            ingredients?.min === 1 &&
            ingredients?.max === 1 &&
            ingredients?.extendable_limit === 1;
          return (
            <>
              <React.Fragment>
                {ingredients?.modifiers_groups_modifiers?.length > 0 ? (
                  <React.Fragment key={ingredients?.id}>
                    {showGroup(ingredients) ? (
                      <Container
                        fluid
                        className={`${styleClasses.try2_all_dressing} modifiersContainer`}
                      >
                        <Accordion
                          defaultActiveKey={isBoxLunch ? '0' : ''}
                          className="modifiers-accordions-wrapper"
                        >
                          <Accordion.Item eventKey="2">
                            <Accordion.Header
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <span
                                className={`d-block text-capitalize font-plex f-s20 f-w5`}
                              >
                                {label}
                                {!!showResetButton && !!!showButtonOfReset && (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleResetButton(ingredients);
                                    }}
                                    className='border-0 bg-dark-red rounded-pill px-4 py-2 ms-3 clr-white f-s18 f-w5 font-plex'
                                  >
                                    Reset Counters
                                  </button>
                                )}
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <ItemModifierGroups
                                  ingredients={ingredients}
                                  selectedAddOns={selectedAddOns}
                                  selectedCore={selectedCore}
                                  handleClick={handleClick}
                                  isLimitExceed={isLimitExceed}
                                  isItEdit={isItEdit}
                                  itemModifierItemModalWidth={''}
                                  cardColSize={cardColSize}
                                  Item={Item}
                                />
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Container>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </>
          );
        })}
    </>
  );
};

export default ModifierGroups;
