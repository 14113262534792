import Loader from 'src/components/Loader/Loader';

import { verifyStatus } from '../../../helper/customizedItem/customizedItem.helper';
import { cartItem } from '../../../models/cart.model';
import { IOfferItem } from 'src/models/rewards.model';

import CartItem from './CartItem';
import styles from './CartItems.module.scss';
import { useAppSelector } from 'src/redux/store/store';

interface ICartItemsList {
  selectedOfferItem: IOfferItem;
  subTotal: number;
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: () => void,
  ) => void;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number,
  ) => void;
  cart: cartItem[];
  loading: boolean;
  syncingCart: boolean;
}

const CartItemsList = ({
  syncingCart,
  selectedOfferItem,
  subTotal,
  handleItemRemove,
  handleQuantityChange,
  loading,
  cart,
}: ICartItemsList) => {
  if (syncingCart)
    return (
      <div className="py-5 d-flex justify-content-center">
        <Loader className={styles.cart_spinner} />
      </div>
    );
    const { currentStep, steps } = useAppSelector((state) => state.wizard);
  return (
    <>
      <ul className={`${currentStep === 4 || currentStep === 3 ? `${styles.cartCheckoutItemList} ${styles.items_list}` : styles.items_list}`}>
        {cart.length > 0 ? (
          cart.map((item: cartItem, idx: number) => (
            <CartItem
              key={item.id}
              selectedOfferItem={selectedOfferItem}
              subTotal={subTotal}
              item={item}
              iteratingIndex={idx}
              handleItemRemove={handleItemRemove}
              handleQuantityChange={handleQuantityChange}
              isActive={verifyStatus(item, 'item_location')}
              loading={loading}
            />
          ))
        ) : (
          <h1 className="ls-normal font-inter f-s16 text-capitalize text-center light-blue f-w5 mb-0 py-4">
            Your cart is empty
          </h1>
        )}
      </ul>
    </>
  );
};

export default CartItemsList;
