import React from 'react';
import * as Constants from 'src/constants';

import { filterArraysBasedOnGroupId } from '../../helper/customizedItem/customizedItem.helper';
import {
  ICoreModifiers,
  IModifierGroups,
  IParams,
} from '../../models/item.model';
import {
  IBucketSelectedIngredients,
  IOptionalModifier,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { ADDITIONAL_ADDED } from '../../priceCalculation/constants';

import CoreModifiers from './ingredients/CoreModifiers';
import ModifierGroups from './ingredients/ModifierGroups';
import RequiredModifier from './ingredients/RequiredModifier';

const AddBoxLunchIngredients: React.FC<IOptionalModifier> = (props) => {
  const {
    coreModifiers,
    modifierGroups,
    requiredModifiers,
    handleIngredientSelection,
    sectionSelected,
    noDressingHandler,
    itemName,
    isItEdit,
    Item,
    isCYOSColSize,
  } = props;

  const handleCoreModifierClick = (
    data: IParams,
    modifier: ICoreModifiers,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifier?.modifier_group_id,
        modifier_type: type,
      },
    });
  };

  const handleClick = (
    data: IParams,
    modifierGroup: IModifierGroups,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifierGroup?.id,
        modifier_group_max: modifierGroup?.max,
        modifier_group_base: modifierGroup?.base,
        modifier_type: type,
      },
    });
  };

  const selectedAddOns = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.ADD_ONS,
      sectionSelected,
    );
  })();

  const selectedCore: IBucketSelectedIngredients[] = (function () {
    const result = itemBuckets.specificItemBucketSelectedModifiers(
      Constants.CORE_MODIFIERS,
      sectionSelected,
    );
    return result;
  })();

  const selectedAddedAddOns: IBucketSelectedIngredients[] = (function () {
    const fromItem: 0 | 1 = (parseInt(sectionSelected) - 1) as 0 | 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.ADD_ONS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const coreModifierBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.CORE_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
  })();

  const isItCoreRelated = (groupId: number) => {
    if (coreModifierBucket?.memoryChip) {
      const isExist = coreModifierBucket['memoryChip'][groupId];
      if (isExist === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isLimitExceed = (
    groupId: number,
    extendable_limit: number,
    groupMin,
  ) => {
    if (extendable_limit === 1 && groupMin === 1) {
      return false;
    } else {
      if (!isItCoreRelated(groupId)) {
        const selectedModifierForCurrentGroup: IBucketSelectedIngredients[] =
          filterArraysBasedOnGroupId([selectedAddedAddOns], groupId);
        return selectedModifierForCurrentGroup.length >= extendable_limit
          ? true
          : false;
      } else {
        if (coreModifierBucket['memoryChip'][groupId]?.changes) {
          return coreModifierBucket['memoryChip'][groupId]?.changes?.quantity >=
            extendable_limit
            ? true
            : false;
        } else {
          return coreModifierBucket['memoryChip'][groupId]?.quantity >=
            extendable_limit
            ? true
            : false;
        }
      }
    }
  };

  return (
    <div className="other-dressing-view try2_dressing_modal box-lunches-modal-inner">
      {/* <Scrollbars className="Modal_inner_scrollbar customization_inner_scrollbar apply-btn-view"> */}
      {coreModifiers?.length ? (
        <CoreModifiers
          coreModifiers={coreModifiers}
          selectedCore={selectedCore}
          handleCoreModifierClick={handleCoreModifierClick}
          isLimitExceed={isLimitExceed}
          Item={Item}
        />
      ) : null}
      {requiredModifiers?.length ? (
        <RequiredModifier
          sectionSelected={sectionSelected}
          itemIngredients={requiredModifiers}
          handleIngredientSelection={handleIngredientSelection}
          noDressingHandler={noDressingHandler}
          showMaxSelection={
            itemName.toLowerCase() ===
            Constants.CREATE_YOUR_OWN_SALAD_TRAY.toLowerCase()
              ? false
              : true
          }
          // customizationModule={true}
          showAdditionalPrice={true}
          Item={Item}
          isCYOSColSize={isCYOSColSize}
        />
      ) : null}
      {modifierGroups?.length ? (
        <ModifierGroups
          modifierGroups={modifierGroups}
          selectedAddOns={selectedAddOns}
          selectedCore={selectedCore}
          handleClick={handleClick}
          isLimitExceed={isLimitExceed}
          isItEdit={isItEdit}
          isBoxLunch={true}
          Item={Item}
        />
      ) : null}
      {/* </Scrollbars> */}
      {/* <hr className="d-md-none" /> */}
    </div>
  );
};

export default AddBoxLunchIngredients;
