import React from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import * as Constants from 'src/constants';
import { filterArraysBasedOnGroupId } from 'src/helper/customizedItem/customizedItem.helper';
import { roundOffLimit } from 'src/helper/helperMethods';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { ADDITIONAL_ADDED } from 'src/priceCalculation/constants';

import NoDressing from '../NoDressing';

import ItemModifierRequiredGroups from './ModifierGroups/ItemModifierRequiredGroups';

const RequiredModifier: React.FC<any> = ({
  itemIngredients,
  sectionSelected,
  handleIngredientSelection,
  noDressingHandler,
  showMaxSelection,
  customizationModule,
  showAdditionalPrice,
  itemModifierItemModalWidth,
  refToScrollToMissingRequiredArea,
  isCreateYourOwnSalad,
  CYOSScrollToMissingRequiredArea,
  Item,
  isCYOSColSize,
}) => {
  const handleClick = (data, in_item, index) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: itemIngredients[index]?.id,
        modifier_group_max: itemIngredients[index]?.max,
        modifier_group_base: itemIngredients[index]?.base,
        brink_modifier_group_id:
          itemIngredients[index]?.brink_modifier_group_id,
        in_item,
        in_slide: true,
        modifier_type: Constants.REQUIRED_MODIFIERS,
      },
    });
  };
  const selectedIngredients = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.REQUIRED_MODIFIERS,
      sectionSelected,
      // 1,
    );
  })();

  const selectedAddedIngredients = (function () {
    const fromItem: any = parseInt(sectionSelected) - 1;

    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const isLimitExceed = (extendable_limit: number, groupId) => {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients?.find(
      (mod: any) => mod.modifier_name === Constants.NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    const modifierGroupsArray = filterArraysBasedOnGroupId(
      [selectedAddedIngredients],
      groupId,
    );
    if (modifierGroupsArray.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  };

  const getNoDressing = (groupId) => {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
    if (requiredModifiersBucket?.noDressings) {
      const isNoDressing = requiredModifiersBucket?.noDressings?.find(
        (e) => e.id === groupId,
      );
      if (isNoDressing) {
        return isNoDressing.noDressing;
      }
      return false;
    } else {
      return false;
    }
  };

  const AdditionalPriceCalculation = (ingredients) => {
    let remainingBase = ingredients?.base || 0;
    const base = ingredients?.base || 0;
    let totalCalculatedPrice = 0;
    if (selectedIngredients?.length > 0) {
      selectedIngredients?.forEach((element) => {
        if (element.modifier_group_id === ingredients?.id) {
          totalCalculatedPrice =
            totalCalculatedPrice + element?.quantity * element?.display_price;
        }
      });
      if (totalCalculatedPrice > base) {
        remainingBase = 0;
      } else {
        remainingBase = remainingBase - totalCalculatedPrice;
      }
    }
    return remainingBase.toFixed(2);
  };

  const showRedBorderAnimation = (index) => {
    return isCreateYourOwnSalad && CYOSScrollToMissingRequiredArea
      ? CYOSScrollToMissingRequiredArea?.isCYOSRequiredModifierSelected
          .status &&
          CYOSScrollToMissingRequiredArea?.isCYOSRequiredModifierSelected
            .highLightArea === index
      : false;
  };

  return (
    <>
      {itemIngredients &&
        itemIngredients?.map((ingredients, index) => {
          const noDressing = getNoDressing(ingredients?.id);
          const labelPrices = AdditionalPriceCalculation(ingredients);
          const label = ingredients?.label?.toLowerCase();
          return (
            <Container fluid key={ingredients?.id} className="modifiersContainer">
              <Accordion
                defaultActiveKey={['2']}
                className={
                  customizationModule
                    ? 'item-customization-acc theme-custom-accordions'
                    : 'modifiers-accordions-wrapper'
                }
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span
                      className="d-block text-capitalize f-s20 f-w5 font-plex"
                      ref={
                        isCreateYourOwnSalad && CYOSScrollToMissingRequiredArea
                          ? CYOSScrollToMissingRequiredArea
                              .refToScrollToMissingRequiredArea[index]
                          : null
                      }
                    >
                      {label}
                      <span className="f-s13 f-w6 font-plex clr-dark-grey d-block">
                        {showMaxSelection ? (
                          `Select up to ${roundOffLimit(ingredients?.max)}`
                        ) : (
                          <span className="caption fs-14">Required</span>
                        )}{' '}
                      </span>
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      className={
                        customizationModule ? 'single-item-modifiers' : 'row'
                      }
                    >
                      <ItemModifierRequiredGroups
                        ingredients={ingredients}
                        selectedIngredients={selectedIngredients}
                        handleClick={(data, in_item) =>
                          handleClick(data, in_item, index)
                        }
                        isLimitExceed={(e) => isLimitExceed(e, ingredients.id)}
                        customizedItemSize={customizationModule && true}
                        itemBase={ingredients?.base / ingredients?.max}
                        remainingBase={labelPrices}
                        showAdditionalPrice={showAdditionalPrice}
                        itemModifierItemModalWidth={itemModifierItemModalWidth}
                        showRedBorderAnimation={showRedBorderAnimation(index)}
                        Item={Item}
                        isCYOSColSize={isCYOSColSize}
                        isCreateYourOwnSalad={isCreateYourOwnSalad}
                      />
                      {ingredients.min === 0 && (
                        <div
                          className={`${
                            customizationModule
                              ? 'item__modifier_item'
                              : 'py-1 py-md-3 col-md-3 col-4 px-2 col-lg-2'
                          } ${itemModifierItemModalWidth}
                            ${isCYOSColSize}`}
                        >
                          <NoDressing
                            label={ingredients?.label}
                            groupId={ingredients?.id}
                            noDressingHandler={noDressingHandler}
                            isNoDressing={noDressing}
                            itemNo={1}
                            showRedBorderAnimation={showRedBorderAnimation(
                              index,
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          );
        })}
    </>
  );
};

export default RequiredModifier;
